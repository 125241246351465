import CheckBrowser from "@onlinesales-ai/app-v2/src/detectBrowser";

var hostname = window.location.hostname;
var message = "You are currently using __BROWSER_NAME__ - __CURRENT_VERSION__. Use __BROWSER_NAME__ > __MIN_VERSION__ to access the application.";

if (
  hostname.match("blibli.onlinesales.ai") || 
  hostname.match("blibli-staging.onlinesales.ai") || 
  hostname.match("blibli-v2.onlinesales.ai") || 
  hostname.match("blibli-ops.onlinesales.ai")){
  message = "Please upgrade your system to use this feature!";
}

CheckBrowser(message);