import {detect} from "detect-browser";
var browser = detect();

var getVersion = function(versionStr){
  var version = Number.NaN;

  if(versionStr){
    var parts = versionStr.split(".");
    if(parts.length > 1){
      version = parts[0];
    }
  }

  return version;
}

var isSupported = true, browserMinVersion = null;;

if(!browser || !browser.name){
  isSupported = false;
}

var browserVersion = getVersion(browser?.version);

// handle the case where we don't detect the browser
switch (isSupported && browser?.name) {
  case 'chrome': {
    browserMinVersion = 60;
    if(browserVersion < browserMinVersion){
      isSupported = false;
    }
  }
  break;
  case 'firefox':{
    browserMinVersion = 60;
    if(browserVersion < browserMinVersion){
      isSupported = false;
    }
  }
  break;

  case 'ie': {
    browserMinVersion = 11;
    isSupported = false;
  }
  break;
}

var CheckBrowser = function(message){

  message = message.replace(/__BROWSER_NAME__/g, browser?.name || "");
  message = message.replace(/__CURRENT_VERSION__/g, browserVersion);
  if(browserMinVersion){
    message = message.replace(/__MIN_VERSION__/g, browserMinVersion);
  }

  if(!isSupported){
    document.body.innerHTML = 
      '<div style="font-size: 18px; text-align: center; background: var(--header-border); margin: 20px 10px; padding: 90px 30px; box-shadow: 0px 0px 10px rgba(156, 156, 156, 0.8);">' +
      message + 
      '</div>';
  }
}

export default CheckBrowser;
